.aboutMe {
  position: relative;
  background-color: white;
  opacity: 0.8;
  padding-top: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.aboutMe h1 {
  padding-bottom: 35px;
  
}

.a_propos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 10%;
}

strong {
  font-weight: 600;
}

.liens {
  display: flex;
  width: 250px;
  margin-top: 65px;
  justify-content: space-between;
  align-items: center;
}

.liens a {
  position: relative;
}

.liens a::before {
  content: attr(message);
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  bottom: -120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 150px;
  text-align: center;
}

.liens a:hover::before {
  opacity: 1;
}


a img {
  height: 50px;
  border-radius: 8px;
  transition: transform 200ms;
  &:hover {
    transform: scale(1.3);
  }
}

@media screen and (max-width: 767px) {
  .aboutMe h1{
    width: 90%;
    font-size: 44px;
  }
}