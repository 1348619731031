.competences {
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  background: none;
  justify-content: center;
  z-index: 1;
  padding-bottom: 40px;
  padding-inline : 20px;
}

.competences .comp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  margin-left: 8%;
  justify-content: space-between;
  margin-top: 25px;
}

.competences .comp h1{
margin-bottom: 25px;
}

.competences .comp h3{
margin-bottom: 15px;
margin-top: 30px;
}

.competences .comp ul{
padding-inline: 25px;
  }

.competences .comp #langages {
  display: flex;
  width: auto;
  gap: 25px;
  flex-wrap: wrap;
}

.competences .comp #logiciels {
  display: flex;
  width: auto;
  gap: 25px;
  flex-wrap: wrap;
}

.competences img {
  height: 50px;
  border-radius: 8px;
  transition: transform 200ms;
  &:hover {
    transform: scale(1.3);
  }
}

.competences .react {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .competences h1{
    width: 90%;
    font-size: 36px;
  }
}
