:root {
    --cd-txt: #3a2d9a;
    --cd-danger: #b20a37;
    --cd-txt--invalid: #fff;
}

.contact {
    padding-top: 55px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: rgb(198, 190, 190);
    opacity: 0.8;
    align-items: center;
    padding-bottom: 165px;
}

.contact h1 {
    padding-bottom: 35px;
}

.contact p {
    padding-bottom: 25px;
    font-size: 20px;
}

.contact a:hover {
font-weight: 400;
}

form {
    
    position: relative;
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 550px;
    color: black;
    padding-bottom: 35px;
}

label {
    margin-bottom: 10px;
    font-size:19px;
}

input {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
    font-size: 20px;
    border-radius: 13px;
    box-shadow: 10px 10px 5px 0px #888888;
    transition: background-color 200ms;
    &:focus {
        background-color: var(--cd-txt);
        }
        &:not(:focus):invalid {
        background: var(--cd-danger);
        border: 2px solid var(--cd-danger);
        color: var(--cd-txt--invalid);
    }
}

textarea {
resize: none;
    height: 180px;
    width: 100%;
    border-radius: 13px;
    box-shadow: 10px 10px 5px 0px #888888;
}

form div {
    display: flex;
    width: 100%;
    justify-content: center;
}

#submit {
    color: black;
    margin-top: 20px;
    width: 140px;
    cursor: pointer;
}

#message {
    font-family:'MS Shell Dlg 2';
    font-size: 20px;
}

.alert {
    position: absolute;
    top: 530px;
    opacity: 0;
    transition: opacity 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid grey 1px;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
}

.boutonSuppression {
    width: 100px;
    height: 29px;
   
        cursor: pointer;
    font-size: 18px;
    color: black;

}

@media screen and (max-width: 767px) {
.contact h1{
    padding-top: 85px;
    padding-inline: 25px;
    font-size: 44px;
}

.contact a {
    font-size: 18px;
}

p {
width: 80%;

}
form {
    width: 80%
}
}