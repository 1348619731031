.tableau {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
   /* background-color: blue;*/
    padding: 15px;
  }

  .cards {
    display: flex;
    width: 100%;
    /*background-color: pink;*/
    flex-wrap: wrap;
     justify-content: space-around;
  }

  .card {
    width: 29%;
    min-width: 350px;
    margin-inline: 15px ; 
    max-width: 380px;
    margin-bottom: 45px;
  }

  .card a {
    position: relative;
  }
  
  .card a::before {
    content: attr(message);
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
    bottom: 250px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    width: 150px;
    text-align: center;
    z-index: 1;
  }
  
  .card a:hover::before {
    opacity: 1;
  }

  .descriptif {
    padding: 10px;
  } 

.tableau img {
   width: 100%;
   height: 560px;
   border-radius: 20px;
   box-shadow: 10px 10px 5px 0px #888888;
   transition: transform 200ms;
   &:hover {
    transform: scale(1.04);
    }
  }

  .tableau h2 {
    text-decoration: underline;
    margin-bottom: 15px;
  }

  .tableau h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 9px;
  }

  .tableau p {
    text-align: justify;
  }