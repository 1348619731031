.navigation {
  position: fixed;
  background-color: white;
  display: flex;
  height: 65px;
  width: 100%;
  z-index: 6;
  justify-content: flex-end;
  align-items: center;
}
/* ok */
.navigation ul {
  display: flex;
  width: min-content;
  gap: 12px;
  padding-right: 80px;
}

/* ok */
.navigation ul li {
  display: flex;
  text-decoration: none;
  height: 30px;
  width: auto;
  justify-content: center;
}

.navigation ul li a {
  display: flex;
  width: auto;
  min-width: 150px;
  flex-wrap: wrap;
  justify-content: center;
  text-decoration: none;
  color: black;
  font-size: 20px;
}

a:hover {
  font-weight: 600;
  color: black;
}

@media screen and (max-width: 1024px) {
  /* Votre CSS pour la tablette ici */

  .navigation {
    justify-content: center;
  }

  .navigation ul {
    /* padding-top: 25px; */
    padding-inline: 25px;
    /* padding-bottom: 15px; */
  }
}

@media screen and (max-width: 767px) {
  .navigation {
    height: auto;
    width: 100%;
  }

  .navigation ul {
    padding-top: 25px;
    padding-bottom: 15px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    /* justify-content: center; */
  }

  .navigation ul li a {
    min-width: 10px;
  }
}
